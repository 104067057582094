import revive_payload_client_4sVQNw7RlN from "/home/e1/remtechalloys.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/e1/remtechalloys.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/e1/remtechalloys.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/e1/remtechalloys.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/e1/remtechalloys.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/e1/remtechalloys.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/e1/remtechalloys.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/e1/remtechalloys.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BEnDUEy4ze from "/home/e1/remtechalloys.com/node_modules/nuxt-unhead/dist/runtime/plugin.mjs";
import plugin_adVF2uRka6 from "/home/e1/remtechalloys.com/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_client_UYiXMU8ZyN from "/home/e1/remtechalloys.com/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import chunk_reload_client_UciE0i6zes from "/home/e1/remtechalloys.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import primevue_TdXjRgL1MA from "/home/e1/remtechalloys.com/plugins/primevue.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BEnDUEy4ze,
  plugin_adVF2uRka6,
  plugin_client_UYiXMU8ZyN,
  chunk_reload_client_UciE0i6zes,
  primevue_TdXjRgL1MA
]