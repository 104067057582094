import { default as index3Yq9BP1755Meta } from "/home/e1/remtechalloys.com/pages/about/index.vue?macro=true";
import { default as _91handle_93lPn0zmtdYgMeta } from "/home/e1/remtechalloys.com/pages/collections/[handle].vue?macro=true";
import { default as indexUGO58F9emiMeta } from "/home/e1/remtechalloys.com/pages/index.vue?macro=true";
import { default as indexjySGtHVYXnMeta } from "/home/e1/remtechalloys.com/pages/rfq/index.vue?macro=true";
export default [
  {
    name: index3Yq9BP1755Meta?.name ?? "about",
    path: index3Yq9BP1755Meta?.path ?? "/about",
    meta: index3Yq9BP1755Meta || {},
    alias: index3Yq9BP1755Meta?.alias || [],
    redirect: index3Yq9BP1755Meta?.redirect,
    component: () => import("/home/e1/remtechalloys.com/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: _91handle_93lPn0zmtdYgMeta?.name ?? "collections-handle",
    path: _91handle_93lPn0zmtdYgMeta?.path ?? "/collections/:handle()",
    meta: _91handle_93lPn0zmtdYgMeta || {},
    alias: _91handle_93lPn0zmtdYgMeta?.alias || [],
    redirect: _91handle_93lPn0zmtdYgMeta?.redirect,
    component: () => import("/home/e1/remtechalloys.com/pages/collections/[handle].vue").then(m => m.default || m)
  },
  {
    name: indexUGO58F9emiMeta?.name ?? "index",
    path: indexUGO58F9emiMeta?.path ?? "/",
    meta: indexUGO58F9emiMeta || {},
    alias: indexUGO58F9emiMeta?.alias || [],
    redirect: indexUGO58F9emiMeta?.redirect,
    component: () => import("/home/e1/remtechalloys.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjySGtHVYXnMeta?.name ?? "rfq",
    path: indexjySGtHVYXnMeta?.path ?? "/rfq",
    meta: indexjySGtHVYXnMeta || {},
    alias: indexjySGtHVYXnMeta?.alias || [],
    redirect: indexjySGtHVYXnMeta?.redirect,
    component: () => import("/home/e1/remtechalloys.com/pages/rfq/index.vue").then(m => m.default || m)
  }
]